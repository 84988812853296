import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/vsts/work/1/s/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Navigation } from "../../src/components/Navigation";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "overview"
    }}>{`Overview`}</h1>
    <p>{`It's time to get our hands dirty by creating our first customization. 👷`}</p>
    <p>{`This is what we'll be covering in this part:`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/my-first-extension/install-tools"
        }}>{`Installing the necessary tools and programs`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/my-first-extension/create-app"
        }}>{`Creating a new app`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/my-first-extension/create-sandbox"
        }}>{`Creating a new sandbox environment`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/my-first-extension/publish-app"
        }}>{`Publishing your app to the sandbox`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/my-first-extension/publish-to-devops"
        }}>{`Saving the project on DevOps`}</a></li>
    </ol>
    <p>{`Let's get started! 🚀`}</p>
    <Navigation previous="/introduction/our-job" next="/my-first-extension/install-tools" mdxType="Navigation" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      